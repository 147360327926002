import React, { Fragment, useEffect } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import LoaderAlone from "../../Loader/LoaderAlone";
import {
  AssignPicklisttoUsersActions,
  AssignSecondaryOrderActions,
  ReAssignPicklisttoUsersActions,
  ReAssignSecondaryOrderActions,
  getAllAssigneeForPicklistActions,
} from "../../../Actions/PickerMgmt/PickerMgmtActions";
import { useState } from "react";
import "./PicklistAssignedUser.css";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { BASE_URL, IMG_DEFAULT } from "../../../Base_URL/BaseURL";
import { Button } from "@material-ui/core";
import { useAlert } from "react-alert";
import {
  ASSIGN_PICKLIST_TO_USERS_RESET,
  ASSIGN_SECONDARY_ORDERS_PICKLIST_RESET,
  REASSIGN_PICKLIST_TO_USERS_RESET,
  REASSIGN_SECONDARY_ORDERS_PICKLIST_RESET,
} from "../../../Constants/PickerMgmt/PickerManagementConstants";
import { useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

// ***********Adding Some Comment to Visualize the git*******************

const PicklistAssignmentUser = (props) => {
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { assignee_users, loading } = useSelector((state) => state.assignee);
  const { close, picklist_id, order_id, handleCheckBoxRefresh } = props;

  const {
    assign_Picklist,
    loading: assignLoading,
    isAssigned,
  } = useSelector((state) => state.assignPicklist);
  const {
    Reassign_Picklist,
    loading: reassignLoading,
    isAssigned: reassigned,
  } = useSelector((state) => state.reassignPicklist);

  // assign secondary picklist single order assignment
  const {
    secondary_assignment_picklist,
    loading: secondary_assignment_loading,
    isSecAssigned,
  } = useSelector((state) => state.assignSecondaryOrders);

  // reassign secondary Picklist signle order reassignement
  const {
    Resecondary_assignment_picklist,
    loading: secondary_reassignment_loading,
    isReSecAssigned,
  } = useSelector((state) => state.reAssignSecondaryOrders);

  // const [search_arr, setSearch_arr] = useState([]);

  useEffect(() => {
    dispatch(
      getAllAssigneeForPicklistActions(
        "picker_list_for_assignment",
        token,
        100,
        1
      )
    );
  }, [dispatch, token]);

  const closeModal = useCallback(() => {
    close(null);
  }, [close]);

  //   pagination code
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // const fetchNext = () => {
  //   setIsLoading(true);
  //   axios
  //     .post(`${BASE_URL}`, {
  //       service_name: "picker_list_for_assignment",
  //       token,
  //       limit: 10,
  //       page: page,
  //     })
  //     .then((response) => {
  //       if (response?.data?.data?.length === 0) {
  //         setHasMore(false);
  //         setIsLoading(false);
  //       } else {
  //         const mergedAgents = Array.from(
  //           new Set([...users, ...response?.data?.data])
  //         );
  //         setUsers(mergedAgents);
  //         let searchbox_arr = mergedAgents.map((i) => {
  //           return {
  //             name: i.user_name,
  //             id: i.user_id,
  //           };
  //         });
  //         setSearch_arr(searchbox_arr);
  //         console.log("SEARCHBOX ARR: ", searchbox_arr);
  //         setIsLoading(false);
  //         setPage((prevPage) => prevPage + 1);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoading(false);
  //     });
  // };

  const fetchNext = () => {
    setIsLoading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "picker_list_for_assignment",
        token,
        limit: 100,
        page: page,
      })
      .then((response) => {
        if (response?.data?.data?.length === 0) {
          setHasMore(false); // No more data to load
        } else {
          const mergedAgents = Array.from(
            new Set([...users, ...response?.data?.data])
          );
          setUsers(mergedAgents);
          // let searchbox_arr = mergedAgents.map((i) => {
          //   return {
          //     name: i.user_name,
          //     id: i.user_id,
          //   };
          // });
          // setSearch_arr(searchbox_arr);
          setPage((prevPage) => prevPage + 1); // Increment page
        }
        setIsLoading(false); // Stop loading
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false); // Stop loading on error
      });
  };

  useEffect(() => {
    if (assignee_users && assignee_users?.data) {
      setUsers(assignee_users?.data);
      // let searchbox_arr = assignee_users?.data.map((i) => {
      //   return {
      //     name: i.user_name,
      //     id: i.user_id,
      //   };
      // });
      // setSearch_arr(searchbox_arr);
    }
  }, [assignee_users]);

  const [activeLoading, setActiveLoading] = useState();

  const handleAssignment = (user_id, index) => {
    setActiveLoading(index);
    if (props.modalNo === 0 || props.modalNo === 2) {
      if (picklist_id && picklist_id !== "") {
        dispatch(
          AssignPicklisttoUsersActions(
            "assign_picklist",
            token,
            picklist_id,
            user_id
          )
        );
      }
      if (order_id && order_id !== "") {
        dispatch(
          AssignSecondaryOrderActions(
            "assign_secondary_order",
            token,
            order_id,
            user_id
          )
        );
      }
    } else if (props.modalNo === 1) {
      if (picklist_id && picklist_id !== "") {
        dispatch(
          ReAssignPicklisttoUsersActions(
            "reassign_picklist",
            token,
            picklist_id,
            user_id
          )
        );
      }
      if (order_id && order_id !== "") {
        dispatch(
          ReAssignSecondaryOrderActions(
            "assign_secondary_order",
            token,
            order_id,
            user_id
          )
        );
      }
    }
  };

  useEffect(() => {
    if (assign_Picklist && assign_Picklist?.status === 1 && isAssigned) {
      alert.success("Assigned Successfully...");
      dispatch({
        type: ASSIGN_PICKLIST_TO_USERS_RESET,
      });
      handleCheckBoxRefresh();
      closeModal();
    } else if (assign_Picklist && assign_Picklist?.status === 0 && isAssigned) {
      alert.error(assign_Picklist && assign_Picklist?.msg);
      dispatch({
        type: ASSIGN_PICKLIST_TO_USERS_RESET,
      });
      handleCheckBoxRefresh();
      closeModal();
    }
  }, [
    dispatch,
    alert,
    assign_Picklist,
    closeModal,
    isAssigned,
    handleCheckBoxRefresh,
  ]);

  useEffect(() => {
    if (Reassign_Picklist && Reassign_Picklist?.status === 1 && reassigned) {
      alert.success("ReAssigned Successfully...");
      dispatch({
        type: REASSIGN_PICKLIST_TO_USERS_RESET,
      });
      closeModal();
    } else if (
      Reassign_Picklist &&
      Reassign_Picklist?.status === 0 &&
      reassigned
    ) {
      alert.error(Reassign_Picklist && Reassign_Picklist?.msg);
      dispatch({
        type: REASSIGN_PICKLIST_TO_USERS_RESET,
      });
      closeModal();
    }
  }, [dispatch, alert, Reassign_Picklist, closeModal, reassigned]);

  useEffect(() => {
    if (
      secondary_assignment_picklist &&
      secondary_assignment_picklist?.status === 1 &&
      isSecAssigned
    ) {
      alert.success("Assigned Successfully...");
      handleCheckBoxRefresh();
      dispatch({
        type: ASSIGN_SECONDARY_ORDERS_PICKLIST_RESET,
      });
      closeModal();
    }
    if (
      secondary_assignment_picklist &&
      secondary_assignment_picklist?.status === 0 &&
      isSecAssigned
    ) {
      alert.error(
        secondary_assignment_picklist && secondary_assignment_picklist?.msg
      );
      handleCheckBoxRefresh();
      dispatch({
        type: ASSIGN_SECONDARY_ORDERS_PICKLIST_RESET,
      });
      closeModal();
    }
  }, [
    dispatch,
    alert,
    secondary_assignment_picklist,
    closeModal,
    isSecAssigned,
    handleCheckBoxRefresh,
  ]);

  useEffect(() => {
    if (
      Resecondary_assignment_picklist &&
      Resecondary_assignment_picklist?.status === 1 &&
      isReSecAssigned
    ) {
      alert.success("ReAssigned Successfully...");
      dispatch({
        type: REASSIGN_SECONDARY_ORDERS_PICKLIST_RESET,
      });
      closeModal();
    } else if (
      Resecondary_assignment_picklist &&
      Resecondary_assignment_picklist?.status === 0 &&
      isReSecAssigned
    ) {
      alert.error(
        Resecondary_assignment_picklist && Resecondary_assignment_picklist?.msg
      );
      dispatch({
        type: REASSIGN_SECONDARY_ORDERS_PICKLIST_RESET,
      });
      closeModal();
    }
  }, [
    dispatch,
    alert,
    Resecondary_assignment_picklist,
    closeModal,
    isReSecAssigned,
  ]);

  // console.log("USERS LIST: ", users);

  // const handleFilterUsers = (val) => {
  //   setPage(1);
  //   let userFilter = users.filter((i) => i.user_id === val.id);
  //   setUsers(userFilter);
  //   console.log("FILTER USER: ", userFilter);
  // };

  // const handleClear = () => {
  //   dispatch(
  //     getAllAssigneeForPicklistActions(
  //       "picker_list_for_assignment",
  //       token,
  //       10,
  //       1
  //     )
  //   );
  // };

  const [user, setUser] = useState("");

  console.log("Users:", users);

  const [Filtered_user, setFilteredUser] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const handleFilterUsers = () => {
    if (user.length === 0) {
      alert.error("Please Type Picker Name to search");
      return;
    }
    console.log("Filter Users", user);
    setPage(1); // Reset page number
    setIsFiltered(true);
    const userFilter = users.filter((i) =>
      i.user_name.toLowerCase().includes(user.toLowerCase())
    );
    setFilteredUser(userFilter);
    console.log("USER FILTER: ", userFilter);
    setHasMore(false); // Disable infinite scroll since it's filtered data
  };

  const handleClear = () => {
    setUser("");
    setIsFiltered(false);
    setFilteredUser([]);
    setPage(2); // Reset to initial page
    setUsers([]); // Clear current users
    setHasMore(true); // Enable infinite scroll
    dispatch(
      getAllAssigneeForPicklistActions(
        "picker_list_for_assignment",
        token,
        100,
        1 // Start from the first page
      )
    );
  };

  // console.log("USERS: ", users);

  return (
    <>
      <HeadingTitle
        title={`${
          props.modalNo === 0
            ? "Assign Picklist"
            : props.modalNo === 1
            ? "Reassign Picklist"
            : props.modalNo === 2 && "Assign Mulitple Picklist"
        }`}
        closeModal={close}
      />
      {loading ? (
        <LoaderAlone />
      ) : (
        <Fragment>
          <div className="search-bar-container">
            <div>
              <input
                type="text"
                value={user}
                onChange={(e) => setUser(e.target.value)}
                placeholder="Search Picker By Name..."
              />
              {user?.length > 0 && (
                <Button onClick={handleClear}>
                  <CloseIcon />
                </Button>
              )}
            </div>
            <Button onClick={handleFilterUsers}>
              <SearchIcon size={24} color="#000" />
            </Button>
          </div>

          {isFiltered ? (
            <Fragment>
              {Filtered_user &&
                Filtered_user.map((i, index) => (
                  <UserCard
                    key={i.user_id}
                    i={i}
                    props={props}
                    assignLoading={assignLoading}
                    activeLoading={activeLoading}
                    index={index}
                    secondary_assignment_loading={secondary_assignment_loading}
                    handleAssignment={handleAssignment}
                    reassignLoading={reassignLoading}
                    secondary_reassignment_loading={
                      secondary_reassignment_loading
                    }
                  />
                ))}
            </Fragment>
          ) : (
            <div id="users" style={{ height: "350px", overflowY: "auto" }}>
              <InfiniteScroll
                dataLength={users && users?.length}
                next={fetchNext}
                hasMore={hasMore}
                scrollableTarget="users"
                loader={isLoading && <LoaderAlone small={true} />}
                style={{ overflow: "auto" }}
              >
                {users &&
                  users.map((i, index) => (
                    <UserCard
                      key={i.user_id}
                      i={i}
                      props={props}
                      assignLoading={assignLoading}
                      activeLoading={activeLoading}
                      index={index}
                      secondary_assignment_loading={
                        secondary_assignment_loading
                      }
                      handleAssignment={handleAssignment}
                      reassignLoading={reassignLoading}
                      secondary_reassignment_loading={
                        secondary_reassignment_loading
                      }
                    />
                  ))}
              </InfiniteScroll>
            </div>
          )}
        </Fragment>
      )}
    </>
  );
};

export default PicklistAssignmentUser;

export const UserCard = ({
  i,
  props,
  assignLoading,
  activeLoading,
  index,
  secondary_assignment_loading,
  handleAssignment,
  reassignLoading,
  secondary_reassignment_loading,
}) => {
  return (
    <div className="person-arr-d-flex" key={i.user_id}>
      <div className="user-icons-assign">
        <div className="user-img-uri">
          <img
            src={i.user_img}
            onError={(e) => (e.target.src = IMG_DEFAULT)}
            alt=""
          />
          <div
            className={`${
              i?.is_present === "0" ? "red" : i?.is_present === "1" && "green"
            }`}
          ></div>
        </div>
        <div className="user-info-pick">
          <p>
            {i.user_name} - {i.user_id}
          </p>
          <p className="pik-qty">
            Picked Qty - {i.picked} | Total Picklist - {i.picklist_count}
          </p>
        </div>
      </div>
      <div>
        {props.modalNo === 0 || props.modalNo === 2 ? (
          <Button
            disabled={
              (assignLoading && activeLoading === index) ||
              (secondary_assignment_loading && activeLoading === index)
                ? true
                : false
            }
            onClick={() => handleAssignment(i.user_id, index)}
            className="assingee"
          >
            {(assignLoading && activeLoading === index) ||
            (secondary_assignment_loading && activeLoading === index)
              ? "Loading"
              : "Assign"}
          </Button>
        ) : props.modalNo === 1 ? (
          <Button
            onClick={() => handleAssignment(i.user_id, index)}
            className="assingee"
            disabled={
              (reassignLoading && activeLoading === index) ||
              (secondary_reassignment_loading && activeLoading === index)
                ? true
                : false
            }
          >
            {(reassignLoading && activeLoading === index) ||
            (secondary_reassignment_loading && activeLoading === index)
              ? "Loading"
              : "Assign"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
