import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Screens/Home/Home";
import NotFound from "./Components/NotFound/NotFound";
import PickerView from "./Screens/PickerView/PickerView";
import PickerManagement from "./Screens/PickerManagement/PickerManagement";
import PickList from "./Screens/PickList/PickList";
import PickListView from "./Screens/PickListView/PickListView";
import SecondaryPicklist from "./Screens/SecondaryPicklist/SecondaryPicklist";
import PortalManagement from "./Screens/PortalManagement/PortalManagement";
import CourierManagement from "./Screens/CourierManagement/CourierManagement";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Activity from "./Screens/Activity/Activity";
import LostSubLocation from "./Screens/LostSubLocation/LostSubLocation";
import LabelGenerator from "./Screens/LabelGenerator/SingleLabelGenerator";
import ToteManagement from "./Screens/ToteManagement/ToteManagement";
import DeliveryPartner from "./Screens/OrderHandover/DeliveryPartner";
import Login from "./Screens/Login/Login";
import ProtectedRoutes from "./Components/ProtectedRoutes/ProtectedRoutes";
import OrderSummary from "./Screens/OrderSummary/OrderSummary";
import CreateOrder from "./Screens/CreateOrder/CreateOrder";
import OrderHandover from "./Screens/OrderHandover/OrderHandover";
import { DataProvider } from "./DataContext/DataContext";
import CommunicationAlert from "./Screens/CommunicationAlert/CommunicationAlert";
import CreateReverseHandover from "./Screens/ReverseOrderHandover/CreateReverseHandover";
import ReverseHandoverSummary from "./Screens/ReverseHandoverSummary/ReverseHandoverSummary";
import ScanOrders from "./Screens/ReverseOrderHandover/ScanOrders";
import MultiLabelGenerator from "./Screens/LabelGenerator/MultiLabelGenerator";
import PredefinedProblems from "./Screens/PredefinedProblems/PredefinedProblems";
import Bypass from "./Screens/ByPass/Bypass";
// import PackingMaterial from "./Screens/PackingMaterial/PackingMaterial";
import AddIdealConsumption from "./Screens/PackingMaterial/AddIdealConsumption";
import ConsumptionReport from "./Screens/PackingMaterial/ConsumptionReport";
import MarkLostRequest from "./Screens/LostSubLocation/MarkLostRequest";
import AcceptRequestWithAudit from "./Screens/LostSubLocation/AcceptRequestWithAudit";
import AdvanceSubLocationTransfer from "./Screens/LostSubLocation/AdvanceSubLocationTransfer";
import ContentInThirdBox from "./Screens/LostSubLocation/ContentInThirdBox";
// import ContentInNewBox from "./Screens/LostSubLocation/ContentInNewBox";
import AdvanceSubLocationTransferSummary from "./Screens/LostSubLocation/AdvanceSubLocationTransferSummary";
import SessionSummary from "./Screens/LostSubLocation/SessionSummary";
import BoxQuantityAudit from "./Screens/BoxAudit/BoxQuantityAudit";
import BoxUnitQuantityAudit from "./Screens/BoxAudit/BoxUnitQuantityAudit";
import BoxAuditTransactionSummary from "./Screens/BoxAudit/BoxAuditTransactionSummary";
import Settings from "./Screens/Settings/Settings";
import CSVTable from "./Screens/PapaParse/CSVTable";
import BypassorderSumarry from "./Screens/ByPass/BypassorderSumarry";
import ByPassOrderMapping from "./Screens/ByPass/ByPassOrderMapping";
import DeviceManagement from "./Screens/DeviceManagement/DeviceManagement";
import qz from "qz-tray";
import axios from "axios";
import { useAlert } from "react-alert";

const App = () => {
  const alert = useAlert();
  // const [handoverData, setHandoverData] = useState(null);
  // const [carrierData, setCarrierData] = useState(null);

  useEffect(() => {
    qz.security.setCertificatePromise(function (resolve, reject) {
      // Preferred method - from server
      fetch("digital-certificate.txt", {
        cache: "no-store",
        headers: { "Content-Type": "text/plain" },
      })
        .then(function (response) {
          if (response.ok) {
            response.text().then(resolve);
          } else {
            response.text().then(reject);
          }
        })
        .catch(reject);

      // Alternate method 1 - anonymous
      // resolve();  // remove this line in live environment

      // Alternate method 2 - direct
      resolve(
        "-----BEGIN CERTIFICATE-----\n" +
          "MIID+TCCAuGgAwIBAgIUFJx1QkZbDOHgHa+ec24vw3o1txIwDQYJKoZIhvcNAQEL\n" +
          "BQAwgYoxCzAJBgNVBAYTAklOMQ8wDQYDVQQIDAZQdW5qYWIxETAPBgNVBAcMCEx1\n" +
          "ZGhpYW5hMQ8wDQYDVQQKDAZTZWVrZXgxEzARBgNVBAsMClNldmVucm9ja3MxDDAK\n" +
          "BgNVBAMMA0dydjEjMCEGCSqGSIb3DQEJARYUZ3J2LndlYmRldkBnbWFpbC5jb20w\n" +
          "IBcNMjMwOTIzMDUwNjMxWhgPMjA1NTAzMTgwNTA2MzFaMIGKMQswCQYDVQQGEwJJ\n" +
          "TjEPMA0GA1UECAwGUHVuamFiMREwDwYDVQQHDAhMdWRoaWFuYTEPMA0GA1UECgwG\n" +
          "U2Vla2V4MRMwEQYDVQQLDApTZXZlbnJvY2tzMQwwCgYDVQQDDANHcnYxIzAhBgkq\n" +
          "hkiG9w0BCQEWFGdydi53ZWJkZXZAZ21haWwuY29tMIIBIjANBgkqhkiG9w0BAQEF\n" +
          "AAOCAQ8AMIIBCgKCAQEAjit0o5WvhQInxccqj7/Xtqgroq6X61gWI/gSQRfpbdMM\n" +
          "6U1MqGW8qzzR5bX+VBURXcdEFokpcxY3/oz17VjPQ0D/bMensZ+EqQubQqmM56zS\n" +
          "BdMlrITCoSbT+cNvA0gB4U4d8UQ58vzZHY0zj6lNFjCZuDfHO+DG+rD9EwjngZzU\n" +
          "+rS8jOb2y7xMu5tuncku/EeEESv1LndGhAmRAfidOUDAL4zbTdLQvz9zP8xcGzas\n" +
          "UhsTBfQ9r4e1b2LKgyIm08oY/eg2qjGWyfPfbrXrzsWpnyJwdHldHMUAAnvdcCxH\n" +
          "bcXSl1upy1wAq1WO4OpPkRlnRLqC09qGWnRAyU4fUQIDAQABo1MwUTAdBgNVHQ4E\n" +
          "FgQU/AtLKKkV14bzAAu99sUVO64c3DYwHwYDVR0jBBgwFoAU/AtLKKkV14bzAAu9\n" +
          "9sUVO64c3DYwDwYDVR0TAQH/BAUwAwEB/zANBgkqhkiG9w0BAQsFAAOCAQEAAA8B\n" +
          "aLZoOZ1w8SVNVDlpSSgq4+Py917Wp1Vp7D6GN6QL0PIO0Y+k6osFXQuWCNzjjSYp\n" +
          "BO7xnr4eHxSlJ9xh4s8d5RlVhCG+F8oRNeIAFsUjj4RLy/kvJWnJI5jcmjZCKhsS\n" +
          "zKGAiki6D4vhePGU+kzezOubGN0YJC5pVpwvTdJLXdiu77IwfTYSMRuxOu9wvhhk\n" +
          "oq/HKpBIYDr5b6frej6H9/o4mCN92qZulhF0bsYhDkvE/4S9cwDaX0i9bV7Ojt17\n" +
          "FCabjHwxjgycLavqJiXCFyfRoF8CyckAVzqMyuVcCP8/3wCM603KB41+LP+c6Hds\n" +
          "D1DnIqq9EEUabJvMyQ==\n" +
          "-----END CERTIFICATE-----\n"
      );
    });

    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        // Preferred method - from server
        axios
          .get(
            "https://erp.sevenrocks.in/app/webroot/qz-local/sign-message.php",
            {
              params: { request: toSign },
              headers: { "Content-Type": "text/plain" },
            }
          )
          .then(function (response) {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch(function (error) {
            reject(error);
          });

        // Alternate method - unsigned
        // resolve(); // remove this line in the live environment
      };
    });
  }, []);

  // signed certificate for qz tray

  useEffect(() => {
    // Check if a connection is already open
    if (!qz.websocket.isActive()) {
      qz.websocket.connect().then(() => {
        return qz.printers.find().catch((err) => alert.error(err.message));
      });
    } else {
      // Handle the case when a connection is already open
      // You can choose to do nothing, show a message, or perform other actions here
      // alert.error("A connection with QZ Tray is already open.");
    }
  }, [alert]);

  return (
    <DataProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route element={<Login />} path="/login" />
          <Route element={<ProtectedRoutes />}>
            <Route element={<Home />} path="/" />
            <Route element={<PickerView />} path="/user-details/:user_id" />
            <Route element={<PickerManagement />} path="/picker-management" />
            <Route element={<PickList />} path="/picklist" />
            <Route element={<PickListView />} path="/picklist-view/:id" />
            <Route element={<SecondaryPicklist />} path="/secondary-picklist" />
            <Route
              element={<CommunicationAlert />}
              path="/communication-alert"
            />
            <Route element={<PortalManagement />} path="/portals-management" />
            <Route element={<CourierManagement />} path="/courier-management" />
            <Route element={<Activity />} path="/activity" />
            <Route
              element={<CreateReverseHandover />}
              path="/create-reverse-handover"
            />
            <Route element={<LostSubLocation />} path="/lost-sublocation" />
            <Route
              element={<LabelGenerator />}
              path="/single-label-generator"
            />
            <Route
              element={<MultiLabelGenerator />}
              path="/multi-label-generator"
            />
            <Route element={<ToteManagement />} path="/tote-management" />
            <Route
              element={
                <DeliveryPartner
                // setHandoverData={setHandoverData}
                // setCarrierData={setCarrierData}
                // handoverData={handoverData}
                // carrierData={carrierData}
                />
              }
              path="/delivery-partner"
            />
            <Route
              element={
                <OrderHandover
                // setHandoverData={setHandoverData}
                // setCarrierData={setCarrierData}
                // handoverData={handoverData}
                // carrierData={carrierData}
                />
              }
              path="/order-handover"
            />
            <Route element={<OrderSummary />} path="/order-summary" />
            <Route
              element={<ReverseHandoverSummary />}
              path="/reverse-handover-summary"
            />
            <Route element={<CreateOrder />} path="/create-order" />
            <Route element={<ScanOrders />} path="/scan-orders" />
            <Route
              element={<PredefinedProblems />}
              path="/predefined-problems"
            />
            <Route element={<AddIdealConsumption />} path="/packing-material" />
            <Route
              element={<AddIdealConsumption />}
              path="/add-ideal-consumptions"
            />
            <Route element={<ConsumptionReport />} path="/consumption-report" />
            <Route element={<Bypass />} path="/bypass" />
            <Route element={<MarkLostRequest />} path="/mark-lost-request" />
            <Route
              element={<AcceptRequestWithAudit />}
              path="/accept-request-audit/:product_id/:subLocation_id"
            />
            <Route
              element={<AdvanceSubLocationTransfer />}
              path="/advance-sublocation-transfer"
            />
            <Route
              element={<ContentInThirdBox />}
              path="/content-in-third-box/:boxname/:qty/:boxname2/:qty2/:session"
            />

            <Route
              element={<AdvanceSubLocationTransferSummary />}
              path="/advance-sublocation-transfer-summary"
            />
            <Route element={<SessionSummary />} path="/session-summary" />
            <Route element={<BoxQuantityAudit />} path="/box-qty-audit" />
            <Route
              element={<BoxUnitQuantityAudit />}
              path="/box-unit-qty-audit"
            />
            <Route
              element={<BoxAuditTransactionSummary />}
              path="/box-audit-transaction-summary"
            />
            <Route element={<Settings />} path="/settings" />
            <Route element={<CSVTable />} path="/upload-csv" />
            <Route
              element={<BypassorderSumarry />}
              path="/bypass-order-summary"
            />
            <Route
              element={<ByPassOrderMapping />}
              path="/bypass-order-mapping"
            />
            <Route element={<DeviceManagement />} path="/device-management" />
          </Route>
          <Route element={<NotFound />} path="*" />
        </Routes>
      </Router>
    </DataProvider>
  );
};

export default App;
