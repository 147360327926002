import React, { useEffect, useState } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { Button } from "@material-ui/core";
import { useCallback } from "react";
import { ChangePicklistStatusActions } from "../../../Actions/PickerMgmt/PickerMgmtActions";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { CHANGE_PICKLIST_STATUS_RESET } from "../../../Constants/PickerMgmt/PickerManagementConstants";

const ChangeStatus = (props) => {
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { close, picklist_id, status } = props;

  const { picklisT_status } = useSelector((state) => state.picklist_status);

  const closeModal = useCallback(() => {
    close(null);
  }, [close]);

  const [isStatusHit, setIsstatusHit] = useState(false);

  console.log("STATUS: ", status);

  const handleStatusPicklist = () => {
    setIsstatusHit(true);
    dispatch(
      ChangePicklistStatusActions(
        "change_picklist_status",
        token,
        picklist_id,
        status
      )
    );
  };

  useEffect(() => {
    if (picklisT_status && picklisT_status?.status === 1 && isStatusHit) {
      alert.success("Status Changed Successfully...");
      dispatch({
        type: CHANGE_PICKLIST_STATUS_RESET,
      });
      closeModal();
    } else if (
      picklisT_status &&
      picklisT_status?.status === 0 &&
      isStatusHit
    ) {
      alert.error(picklisT_status && picklisT_status?.msg);
      dispatch({
        type: CHANGE_PICKLIST_STATUS_RESET,
      });
      closeModal();
    }
  }, [dispatch, picklisT_status, alert, isStatusHit, closeModal]);

  return (
    <>
      <HeadingTitle
        colorRed={true}
        title="Change Picklist Status"
        closeModal={close}
      />
      <h3 className="cancel-picklist-head">
        ARE YOU SURE YOU WANT TO CHANGE THE STATUS OF PICKLIST?
      </h3>
      <div className="flex-cancel-cta">
        <Button onClick={handleStatusPicklist}>YES</Button>
        <Button onClick={closeModal}>NO</Button>
      </div>
    </>
  );
};

export default ChangeStatus;
